.header {
  background-color: #000;
  justify-content: center;
  align-items: stretch;
  padding: 10px;
  display: flex;
}

.div-block {
  background-color: #d37474;
}

.h-menu {
  width: auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.footer {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
}

.content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.f-text-menu {
  max-width: 1000px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.f-links {
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.f-parrafo {
  font-family: Bkant, sans-serif;
  font-size: 16px;
}

.home {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  color: #000;
  background-color: #f4d680;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 20px;
  display: flex;
}

.f-separador {
  width: 100%;
}

.f-icons {
  width: auto;
}

.f-div-suscribirse {
  width: auto;
  height: auto;
  cursor: pointer;
  border: 2px solid #000;
  border-radius: 15px;
  align-items: flex-start;
  margin-bottom: 0;
  padding: 5px 10px;
  display: flex;
}

.f-text-links {
  cursor: pointer;
  font-family: Bkant, sans-serif;
  font-size: 16px;
}

.h-menu-text {
  width: auto;
  height: auto;
  color: #a7722b;
  padding-right: 0;
  font-family: Mtcorsva, sans-serif;
  font-size: 30px;
}

.f-icon {
  width: 75px;
  cursor: pointer;
}

.c-content1 {
  width: 100%;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.c-titulo1 {
  margin-bottom: 20px;
  font-family: Oldengl, sans-serif;
  font-size: 46px;
  line-height: 25px;
}

.c-subtitulo1 {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Itcedscr, sans-serif;
  font-size: 56px;
  line-height: 25px;
}

.c-c1-imgs {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.c-c1-imgs-cen {
  width: auto;
  min-width: 200px;
  justify-content: center;
  align-items: center;
  padding: 0 auto;
  display: flex;
}

.c-c1-imgs-izq, .c-c1-imgs-der {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.c-separador-text {
  width: auto;
  height: auto;
  text-align: center;
  cursor: pointer;
  font-family: Bkant, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.image {
  width: 50px;
  position: relative;
  top: -66px;
}

.image-2, .image-3, .image-4 {
  width: 50px;
}

.image-5 {
  width: 25vw;
}

.image-6 {
  width: 100px;
}

.image-7 {
  width: 70px;
}

.h-menu-content {
  width: auto;
  height: 30px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
  cursor: pointer;
  flex-direction: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.image-8 {
  width: 90px;
}

.div-block-2 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: static;
}

.image-9 {
  width: 25vw;
}

.c-content2 {
  width: 100%;
  max-width: 1000px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.c-c1-img-cen {
  width: 250px;
  cursor: pointer;
}

.c-c1-img {
  width: 100px;
  cursor: pointer;
}

.text-block {
  width: 90%;
  text-align: center;
  font-family: Itcedscr, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 20px;
}

.text-block-2 {
  font-family: Itcedscr, sans-serif;
  font-size: 32px;
  line-height: 20px;
}

.text-block-3 {
  font-family: Bkant, sans-serif;
  font-size: 18px;
  line-height: 10px;
}

.c-c1-textos {
  width: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-4 {
  width: 100%;
}

.c-c2-ct1-text2 {
  text-align: right;
  font-family: Itcedscr, sans-serif;
  font-size: 30px;
}

.c-c2-ct1-text1 {
  color: #fff;
  font-family: Bkant, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.image-10 {
  width: 250px;
  cursor: pointer;
}

.c-c2-ct1-text1-content {
  height: 100px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #833c0b;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
}

.c-c2-cita1 {
  width: 770px;
  max-width: 770px;
  min-width: 60px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.text-block-5 {
  font-size: 100px;
}

.text-block-6 {
  font-size: 14px;
}

.c-separador {
  width: 100%;
  height: 40px;
  background-color: #c45911;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.c-content3 {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.c-c3-testimonios {
  height: auto;
  max-width: 1000px;
  width: 100%;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  object-fit: fill;
  justify-content: center;
  align-items: stretch;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  overflow: auto;
}

.c-c3-img-pilar {
  width: 60px;
}

.c-c3-testimonio {
  width: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.c-c3-test-text {
  text-align: justify;
  font-family: Bkant, sans-serif;
  font-size: 16px;
}

.text-block-7 {
  font-family: Itcedscr, sans-serif;
  font-size: 40px;
}

.text-block-8 {
  width: 1.40561%;
}

.text-block-9 {
  font-size: 35px;
}

.div-block-5 {
  width: 50px;
  height: 50px;
  background-color: #81e054;
  border-radius: 20px;
  position: fixed;
  top: auto;
  bottom: 10px;
  left: auto;
  right: 10px;
}

.image-11 {
  z-index: 1;
  width: 50px;
  cursor: pointer;
  transition: transform .2s;
  position: fixed;
  top: auto;
  bottom: 10px;
  left: auto;
  right: 10px;
}

.c-separador-text-n {
  width: auto;
  height: 25px;
  text-align: center;
  cursor: auto;
  font-family: Bkant, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.image-12 {
  width: 90%;
  height: 35px;
  max-width: 1000px;
}

.c-separador--boton {
  width: auto;
  height: 40px;
  text-align: center;
  background-color: #c45911;
  border: 2px solid #000;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.f-text-menu-izq {
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.h-contenedor {
  width: 100%;
  max-width: 1000px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.bold-text {
  font-size: 35px;
  line-height: 15px;
}

@media screen and (max-width: 991px) {
  .c-c2-cita1 {
    width: 680px;
  }
}

@media screen and (max-width: 767px) {
  .c-c1-imgs {
    flex-direction: column;
  }

  .c-c1-imgs-izq {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }

  .c-c1-imgs-der {
    width: 100%;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: row;
    justify-content: space-around;
  }

  .c-c2-ct1-text1 {
    font-size: 16px;
  }

  .c-c2-ct1-text1-content {
    height: 100%;
  }

  .c-c2-cita1 {
    width: 480px;
  }
}

@media screen and (max-width: 479px) {
  .f-text-menu {
    flex-direction: column;
  }

  .f-links {
    align-items: center;
  }

  .f-parrafo {
    width: auto;
  }

  .f-icons {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .c-titulo1 {
    font-size: 30px;
  }

  .c-subtitulo1 {
    text-align: center;
    font-size: 36px;
    line-height: 30px;
  }

  .c-content2 {
    flex-direction: column;
  }

  .c-c1-textos {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .c-c2-ct1-text1-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .c-c2-cita1 {
    width: 252px;
  }
}


@font-face {
  font-family: 'Oldengl';
  src: url('../fonts/OLDENGL.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Itcedscr';
  src: url('../fonts/ITCEDSCR.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Antquabi';
  src: url('../fonts/ANTQUABI.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Antquai';
  src: url('../fonts/ANTQUAI.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bkant';
  src: url('../fonts/BKANT.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Antquab';
  src: url('../fonts/ANTQUAB.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mtcorsva';
  src: url('../fonts/MTCORSVA.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}