.h-menu-content {

}

.h-menu-content-seleccionado{
    box-shadow: 0 0 10px 1px #f4d680;
    border-radius: 5px;
    color: #A7722B;
    background: #F4D680;
}

.h-menu-content:hover {
    box-shadow: 0 0 10px 1px #f4d680;
    border-radius: 5px;
    color: #A7722B;
    background: #F4D680;
}



.c-separador--boton {
    border: 2px solid #b19703 !important;
    box-shadow: 0px 0px 26px 0px #b19703;
    -webkit-box-shadow: 0px 0px 26px 0px #b19703;
    -moz-box-shadow: 0px 0px 26px 0px #b19703;
}

.c-separador--boton:hover {
    background: #C37A0F;
}


.f-text-links:hover {
    color: rgb(196, 89, 17);
}

.f-text-links-seleccionado {
    color: rgb(196, 89, 17);
    font-family: Bkant, sans-serif;
}


.f-div-suscribirse:hover {
    background-color: rgb(196, 89, 17);
}

.c-c1-img-preview1 {
    height: 85%;
}

.c-c1-img-preview2 {
    height: 60%;
}

.h-contenedor {
    display: flex !important;
    flex-direction: column !important;
}

.h-menu {
    display: flex !important;
    flex-direction: column !important;
}

@media (min-width: 768px) {
    .h-contenedor {
        flex-direction: row !important;
   }
    
    .h-menu {
        flex-direction: row !important;
    }
}